import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';
import DolInfo from 'shared/DolInfo';
import Button from '@dolstaff/shared/es/Button';
import { Link } from 'gatsby';
import { IconsLogoFacebook2 } from 'components/Icon/SVGIcons';
import { UserPixelTracking } from 'utils/userPixelTracking';
import { CourseTextLink } from './components/CourseTextLink';
import { useLandingDifferences } from 'shared/CourseLanding/Differences';
import { Images } from 'shared/CourseLanding/Certificates';
import Modal from 'shared/CourseLanding/Modal';

const Main = styled.div`
  position: relative;
  display: grid;
  gap: 48px;
  width: 100%;
  margin: auto;
  .link-messenger {
    width: fit-content;
    margin: auto;
  }
  .fb-mess {
    margin-right: 6px;
  }
  .btn-action {
    border-radius: 12px;
  }
  .red-line {
    position: absolute;
    width: 52px;
    top: -69px;
    left: 0;
  }
  ${fromScreen(776)} {
    width: 720px;
    .red-line {
      width: 67px;
      top: -105px;
      left: 15%;
    }
  }
  ${fromScreen(1144)} {
    width: 720px;
    .red-line {
      top: -105px;
      left: 12%;
    }
  }
`;
const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 40px;
  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TitleHeader = styled.div`
  display: grid;
  gap: 16px;
  width: 100%;
  text-align: center;
  margin: auto;
  ${fromScreen(375)} {
    width: 260px;
  }
  ${fromScreen(458)} {
    width: 330px;
  }
  ${fromScreen(1144)} {
    width: 440px;
  }
`;

const Item = styled.div`
  display: grid;
  gap: 24px;
  grid-template-rows: minmax(0px, 140px) max-content;
  .dol-image {
    width: 140px;
  }
`;

const DescriptionWrapper = styled.div`
  display: grid;
  gap: 12px;
`;
const DescriptionTitle = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 24px minmax(0, max-content);
  align-items: center;
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DolSolutionSection = ({ title = '', subTitle = '', data }) => {
  const [showModal, setShowModal] = React.useState(false);
  const diffData = useLandingDifferences();
  const diff = diffData?.gcms?.jsonContanier?.content?.differences?.[0];

  const onClick = () => {
    UserPixelTracking.clickEvent();
  };
  const handleAction = type => {
    switch (type) {
      case 'VIEW':
        setShowModal(true);
        break;

      default:
        break;
    }
  };

  return (
    <Main>
      <TitleHeader>
        <Typography variant="medium/16-24" color={colorsV2.neutral120}>
          {subTitle}
        </Typography>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          v3
          as="h2"
          color={colorsV2.neutral180}
        >
          {title}
        </Typography>
      </TitleHeader>

      <MainContent>
        {data?.map((eachItem, idx) => {
          const { title, description, action, imageUrl, ImageAlt } =
            eachItem || {};
          const { type, link } = action || {};
          const { url, text } = link || {};
          return (
            <Item key={idx}>
              <LazyImage className="dol-image" src={imageUrl} alt={ImageAlt} />
              <DescriptionWrapper>
                <DescriptionTitle>
                  <LazyImage
                    src="https://gqefcpylonobj.vcdn.cloud/directus-upload/99415db5-531f-4245-9648-3b315de8a9aa.png"
                    alt="emoji-symbols-check-mark-button"
                  />
                  <Typography variant="bold/20-28" color={colorsV2.black100}>
                    {title}
                  </Typography>
                </DescriptionTitle>
                <Typography variant="medium/16-24" color={colorsV2.neutral180}>
                  {description}
                </Typography>
                <Link to={type === 'LINK' ? url : undefined}>
                  <CourseTextLink
                    text={text}
                    variant="regular/16-24"
                    handleAction={() => handleAction(type)}
                  />
                </Link>
              </DescriptionWrapper>
            </Item>
          );
        })}
      </MainContent>
      <DolInfo>
        {({ dolInfo }) => (
          <Link
            target="_blank"
            href={dolInfo.messenger.href}
            rel="noreferrer noopener"
            className="link-messenger"
          >
            <Button
              size="large"
              className="btn-action"
              type="primary"
              marginBottom="0"
              onClick={onClick}
            >
              <BtnWrapper>
                <Typography
                  className="fb-mess"
                  variant="semi-bold/14-24"
                  color={colorsV2.white100}
                >
                  Liên hệ DOL ngay
                </Typography>
                <IconsLogoFacebook2 />
              </BtnWrapper>
            </Button>
          </Link>
        )}
      </DolInfo>
      <LazyImage
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/caaa2811-9fe5-4434-969d-5fa272556ff9.png"
        alt="dol-solution-section-red-line"
        className="red-line"
      />
      <Modal
        title={
          diff.info.content.link.modalTitle || diff.info.content.link.title
        }
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
      >
        <Images images={diff.info.content.images} />
      </Modal>
    </Main>
  );
};

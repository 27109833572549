import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div`
  display: grid;
  gap: 48px;
  .header-title {
    text-align: center;
    width: 100%;
    margin: auto;
  }
  ${fromScreen(776)} {
    .header-title {
      width: 420px;
    }
  }
  ${fromScreen(1144)} {
    .header-title {
      width: 512px;
    }
  }
`;
const MainContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: end;
  gap: 40px;
  ${fromScreen(1144)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;
const ContentList = styled.div`
  display: grid;
  gap: 24px;
`;
const Item = styled.div`
  display: grid;
  grid-template-columns: 28px minmax(0, max-content);
  gap: 16px;
  padding: 20px 24px;
  border-radius: 16px;
  background: #373c4b;
`;
const DescriptionWrapper = styled.div`
  display: grid;
  gap: 12px;
`;
const DescriptionList = styled.div`
  display: grid;
  gap: 4px;
`;
const ImageWrapper = styled.div`
  display: none;
  justify-content: end;
  .dol-image {
    width: 424px;
  }
  ${fromScreen(1144)} {
    display: grid;
  }
`;

export const WhatYourProblem = ({ title = '', data }) => {
  return (
    <Main>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        as="h2"
        color={colorsV2.white100}
        className="header-title"
      >
        {title}
      </Typography>
      <MainContent>
        <ContentList>
          {data?.map((eachItem, idx) => {
            const { title, descriptions } = eachItem || {};
            return (
              <Item key={idx}>
                <LazyImage
                  src="https://gqefcpylonobj.vcdn.cloud/directus-upload/e2339d53-eb09-47a2-954e-006cc7e3f918.png"
                  alt="emoji-symbols-cross-mark"
                />
                <DescriptionWrapper>
                  <Typography
                    variant="semi-bold/20-28"
                    color={colorsV2.white100}
                  >
                    {title}
                  </Typography>
                  <DescriptionList>
                    {descriptions?.map((description, desIndex) => (
                      <Typography
                        key={desIndex}
                        variant="medium/16-24"
                        color={colorsV2.white80}
                      >
                        {description}
                      </Typography>
                    ))}
                  </DescriptionList>
                </DescriptionWrapper>
              </Item>
            );
          })}
        </ContentList>
        <ImageWrapper>
          <LazyImage
            className="dol-image"
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/7f385f51-f777-4a9b-a216-0a5d8e39bd6a.png"
            alt="dol-junior-problem"
          />
        </ImageWrapper>
      </MainContent>
    </Main>
  );
};
